import { HttpInterceptorFn } from '@angular/common/http';

export const authInterceptor: HttpInterceptorFn = (req, next) => {
  const token = localStorage.getItem("user-token");
  const request = req.clone({
    setHeaders: {
      Authorization: token ? `Bearer ${token}` : "",
    }
  });

  return next(request);
};
