export const environment = {
  firebase: {
    apiKey: "AIzaSyDfEhtIXILWqg1AFJJeI1B7gXPNQAw86YU",
    authDomain: "househunt-b9a38.firebaseapp.com",
    projectId: "househunt-b9a38",
    storageBucket: "househunt-b9a38.firebasestorage.app",
    messagingSenderId: "197957125621",
    appId: "1:197957125621:web:2222af45a64bcff400ccbc",
    measurementId: "G-M6PZ2BMCG7"
  }
};
