import { ApplicationConfig, importProvidersFrom, provideZoneChangeDetection } from '@angular/core';
import { provideRouter, withInMemoryScrolling, withRouterConfig } from '@angular/router';
import { GALLERY_CONFIG, GalleryConfig } from 'ng-gallery';
import { LIGHTBOX_CONFIG, LightboxConfig } from 'ng-gallery/lightbox';

import { routes } from "./app.routes";
import { provideAnimations } from "@angular/platform-browser/animations";
import { provideEnvironmentNgxMask } from "ngx-mask";
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { baseUrlInterceptor } from './core/interceptors/base-url.interceptor';
import { authInterceptor } from './core/interceptors/auth.interceptor';
import { ConfirmationService, MessageService } from 'primeng/api';
import { networkInterceptor } from './core/interceptors/network.interceptor';
import { environment } from '../environments/environment';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getStorage, provideStorage } from '@angular/fire/storage';

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(
      routes,
      withInMemoryScrolling({
        scrollPositionRestoration: 'enabled',
        // anchorScrolling: 'enabled'
      }),
    ),
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideStorage(() => getStorage()),
    provideEnvironmentNgxMask(),
    provideAnimations(),
    provideHttpClient(withInterceptors([baseUrlInterceptor, authInterceptor, networkInterceptor])),
    MessageService,
    ConfirmationService,
    {
      provide: GALLERY_CONFIG,
      useValue: {
        autoHeight: true,
        imageSize: 'cover'
      } as GalleryConfig
    },
    {
      provide: LIGHTBOX_CONFIG,
      useValue: {
        keyboardShortcuts: false,
        exitAnimationTime: 1000
      } as LightboxConfig
    },
  ],
};

// TODO: Fix scrolling issue while updating query parameters in filter component
// TODO: Improve handling of 404
// TODO: Fix UI especially filter/create form inputs, carousel when there is only single listing
