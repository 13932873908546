import { Routes } from '@angular/router';
import { MainLayoutComponent } from './layout/main/main-layout.component';
import { authGuard } from './shared/guards/auth.guard';
import { PageNotFoundComponent } from './shared/pages/page-not-found/page-not-found.component';
import { AUTH_PATH, LISTINGS_PATH, USER_PATH } from './config/routes.constants';

export const routes: Routes = [
  {
    path: "",
    component: MainLayoutComponent,
    children: [
      {
        path: "",
        loadChildren: () => import("./features/home/home.routes").then(m => m.homeRoute),
        data: {
          breadcrumb: "Strona główna",
        }
      },
      {
        // path: "listings",
        path: LISTINGS_PATH,
        loadChildren: () => import("./features/listings/listings.routes").then(m => m.listingsRoutes),
        data: {
          breadcrumb: "Ogłoszenia",
        }
      },
      {
        // path: "user",
        path: USER_PATH,
        canActivate: [authGuard],
        loadChildren: () => import("./features/users/users.routes").then(m => m.usersRoutes),
        // data: {
        //   breadcrumb: "Użytkownik",
        // }
      },
    ]
  },
  {
    path: AUTH_PATH,
    loadChildren: () => import("./features/auth/auth.routes").then(m => m.authRoutes),
  },
  {
    path: "**",
    pathMatch: "full",
    component: PageNotFoundComponent
  }
];
