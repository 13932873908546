import { HttpInterceptorFn } from '@angular/common/http';

export const baseUrlInterceptor: HttpInterceptorFn = (req, next) => {
  const baseUrl = 'https://dev.househunt.pl/api';

  if (!req.url.startsWith('http')) {
    const apiReq = req.clone({
      url: `${baseUrl}${req.url}`,
    });
    return next(apiReq);
  }

  return next(req);
};
