import { Component, inject, OnInit } from '@angular/core';
import { NavigationEnd, NavigationStart, Router, RouterOutlet } from '@angular/router';
import { NoirThemePreset } from './config/primeng/noir-theme-preset';
// import { PrimeNGConfig } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { ProgressBarModule } from 'primeng/progressbar';
import { LoaderService } from './shared/services/loading/loader.service';
import { filter } from 'rxjs/operators';
import { UserService } from './shared/services/user/user.service';
import { LocationService } from './features/listings/services/location.service';
import { LocationPermissionsService } from './features/listings/services/location-permissions.service';
import { ListingsService } from './features/listings/services/listings.service';
import { PrimeNG } from 'primeng/config';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, ToastModule, ProgressBarModule],
  templateUrl: './app.component.html',
  styleUrl: './app.component.css',
})
export class AppComponent implements OnInit {
  public title = 'House Hunt';
  protected loaderService = inject(LoaderService);
  protected router = inject(Router);
  protected userService = inject(UserService);
  protected locationService = inject(LocationService);
  protected locationPermissionService = inject(LocationPermissionsService);
  protected listingsService = inject(ListingsService);

  public primengConfig: PrimeNG = inject(PrimeNG);

  ngOnInit() {
    this.primengConfig.ripple.set(true);
    this.primengConfig.theme.set({
      preset: NoirThemePreset,
      options: {
        cssLayer: {
          name: 'primeng',
          order: 'tailwind-base, primeng, tailwind-utilities',
        },
      },
    });

    // TODO: add also token time validation check
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
      )
      .subscribe(() => {
        const token = this.userService.getUserToken();
        if (!!token) {
          this.userService.isLogged.set(true);
          this.listingsService.saveFollowedListingsToLocalStorage();
        } else {
          this.userService.isLogged.set(false);
        }
      });

    // this.locationService.setBrowserLocation();
    this.locationPermissionService.requestLocation();
  }
}

// TODO: Write custom service which will handle toast messages more nicely
