import { Component } from '@angular/core';
import { SidebarComponent } from '../../../layout/partials/sidebar/sidebar.component';
import { NavComponent } from '../../../layout/partials/nav/nav.component';

@Component({
  imports: [
    SidebarComponent,
    NavComponent,
  ],
  standalone: true,
  selector: "hh-listing-create",
  template: `
    <div class="text-center flex flex-col gap-10">
      <hh-nav
        [active]="sidebarActive"
        (activeChangeEvent)="changeVisibility()" />
      <hh-sidebar
        [active]="sidebarActive"
        (activeChangeEvent)="changeVisibility()" />
      <h1 class="text-7xl font-bold">404</h1>
      <div class="text-5xl font-bold">Page not found :(</div>
    </div>
  `
})
export class PageNotFoundComponent {
  sidebarActive: boolean = false;

  changeVisibility() {
    this.sidebarActive = !this.sidebarActive;
  }
}
