import { HttpInterceptorFn } from '@angular/common/http';
import { LoaderService } from '../../shared/services/loading/loader.service';
import { inject } from '@angular/core';
import { finalize } from 'rxjs';

export const networkInterceptor: HttpInterceptorFn = (req, next) => {
  let totalRequests = 0;
  let requestsCompleted = 0;

  const loaderService = inject(LoaderService);

  loaderService.setIsLoading(true);

  totalRequests++;

  return next(req).pipe(
    finalize(() => {
      requestsCompleted++;

      if (requestsCompleted === totalRequests) {
        loaderService.setIsLoading(false);
        totalRequests = 0;
        requestsCompleted = 0;
      }
    })
  );
};
